import React from "react"
import Seo from "../components/seo"
// https://github.com/FortAwesome/react-fontawesome/issues/234
import { Link } from "gatsby"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

const ProfilePictureDiv = styled.div`
  height: 400px;
  width: 400px;
  
  @media (max-width: 768px) {
    height: 300px;
    width: 300px;
  }
  
  @media (max-width: 400px) {
    height: 150px;
    width: 150px;
  }
`;

export default function Landing3() {
  return <>
    <Seo
      title={''}
    />
    <div className="container">
      <div className="flex flex-wrap flex-row md:h-screen items-center">
        <div className="mx-auto m-10">
          <ProfilePictureDiv>
            <StaticImage
              src="../../static/kristoffer-strom-bergset.jpg"
              style={{borderRadius: '50%'}}
            />
          </ProfilePictureDiv>
        </div>
        <div className="mx-auto text-center sm:text-left">
          <h1 className="text-2xl font-bold dark:text-white">Kristoffer Strøm Bergset</h1>
          <div className="text-xl pb-2">Software Engineer</div>
          <div>
            <p className="py-1">Førde, Norway</p>
            <p className="py-1"><a href="mailto:kristoffer@bergset.no">kristoffer@bergset.no</a></p>
            {/*<p className="py-1">Works @ <a href="https://www.munikum.no" target="_blank" rel="noreferrer">Munikum</a></p>*/}
          </div>

          <div className="pt-5 flex flex-row flex-wrap max-w-xs justify-center md:justify-start">
            <Link className="btn-blue" to={'/cv'}>CV</Link>
            <a className="btn-blue" href="https://github.com/krisberg" target="_blank" rel="noreferrer">Github</a>
            <a className="btn-blue" href="https://gitlab.com/krisberg" target="_blank" rel="noreferrer" aria-label="gitlab">GitLab</a>
            <a className="btn-blue" href="https://www.linkedin.com/in/kristoffer-str%C3%B8m-bergset-4913756a/" target="_blank" rel="noreferrer" aria-label="linkedin">LinkedIn</a>
            <a className="btn-blue" href="https://stackshare.io/krisberg" target="_blank" rel="noreferrer" aria-label="stackshare">StackShare</a>
            <a className="btn-blue" href="https://t.me/kbergset" target="_blank" rel="noreferrer" aria-label="telegram">Telegram</a>
          </div>
        </div>
      </div>
    </div>
  </>
}
